<template>
	<router-view></router-view>
</template>

<script>
import { defineComponent } from 'vue';
import '@/assets/font/download.css'
export default defineComponent({
	name: 'IndexApp',
	components: {
	},
	setup() {
		return {
		}
	}
});
</script>

<style lang="less">
.webpack-dev-server-client-overlay-div,
.webpack-dev-server-client-overlay{
	display: none;
}
</style>
