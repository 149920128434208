import axios from "axios";
import Tools from "@/utils/tools.js";
// import Tools from "@/utils/tools.js";


let MESSSGES = {
    4010: "用户名或密码错误",
};

axios.defaults.headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",//;charset=utf-8
};

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, 
    withCredentials: true, 
    timeout: window.KSGMS_TIMEOUT || 30000,
});
const CancelToken = axios.CancelToken;
const requestSession = {};

// 请求拦截
service.interceptors.request.use(
    (config) => {

        const groupName = config.group || config.Group;

        if (groupName) {
            if (requestSession[groupName] && requestSession[groupName].cancel) {
                requestSession[groupName].cancel();
            }
            config.cancelToken = new CancelToken((cfn) => {
                requestSession[groupName] = {};
                requestSession[groupName].cancel = cfn;
            });
        }
        return config;
    }, (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);


// 响应拦截
service.interceptors.response.use(
    (response) => {

        const res = response.data;
        let code = res.code
        // let message = res.message

        if (code == 401) {
            console.log('未登录');
            Tools.setSItem("isLogin", "");
        }

        // if (![200, 304].includes(code)) {
        //     let msg = message || "接口响应结果失败";
        //     Tools.showErrorMessage(msg);
        //     return Promise.reject(new Error(msg));
        // } else {
        //     return res;
        // }
        return res;
    },
    (error) => {

        error = error || {};
        let response = error.response || {};
        // let status = response.status;
        let data = response.data || {};
        let code = data.code
        let message = data.message
        // let isShowMessage = true; // 不需求显示是在登录页面的，在那边已经在页面中显示了。

        // 用户会话过期，请重新登录(需要重定向到登录页)
        if (code == 403) {
            console.log('无权限');
        }

        if (code == 401) {
            console.log('未登录');
            Tools.setSItem("isLogin", "");
        }

        // 不显示信息的code
        if ([].includes(code)) {
            // isShowMessage = false;
        }

        // 请求被取消时, 程序中断
        if (
            error &&
            error.toString &&
            (error.name === "CanceledError" || error.toString() === "Cancel")
        ) {
            // isShowMessage = false;
        }

        message = MESSSGES[code] || message;
        error.message = message; // message重置
        data.message = message;

        // isShowMessage && Tools.showErrorMessage(message || "接口出错了", error);

        return Promise.reject(error);
    }
);


export default service;
