import { ElMessage } from 'element-plus'
// import _ from 'lodash';
export default {
    keys:{},
    setScrollTime(opts){  // 每次真实发送时调用
        let key = opts.key;
        let currTime = new Date().getTime();
        this.keys[key] = {
            time:currTime,
            scroll:{
                top:opts.top,
                left:opts.left,
            }
        }
    },
    callScrollTopSend(opts){
        return new Promise((rev) => {
            let key = opts.key;
            let time = opts.time ||  50;
            let scroll = JSON.stringify(opts.scroll || {}); // top,left
            let data = this.keys[key] || {}
            let lastTime = data.time || 0;
            let currTime = new Date().getTime();
            let diffTime = currTime - lastTime;
            //  || data.scroll != scroll // 因为
            if(diffTime > time){
                this.keys[key] = {
                    time:currTime,
                    scroll:scroll
                }
                rev(opts);
            }
        })
    },
    showErrorMessage(msg){
        ElMessage.error(msg);
    },
    setSItem(key, value){
        localStorage.setItem(key, value);
    },
    getSItem(key){
        return localStorage.getItem(key);
    },
    diff(newDate, oldDate, context){
        context.data = oldDate;
        // let obj2 = [
        //     {
        //         nodeName:"div",
        //         attrs:{
        //             style:{
        //                 display:"none",
        //             }
        //         },
        //         children:[
        //             {
        //                 nodeName:"span",
        //                 xpath:"//div[1]/span[1]",
        //                 attrs:{
        //                     style:{
        //                         display:"none",
        //                     }
        //                 },
        //                 children:[
            
        //                 ]
        //             },{
        //                 nodeName:"span",
        //                 xpath:"//div[1]/span[2]",
        //                 attrs:{
        //                     style:{
        //                         display:"block",
        //                     }
        //                 },
        //                 children:[
            
        //                 ]
        //             }
        //         ]
        //     }
        // ];
        // let obj3 = [
        //     {
        //         nodeName:"div",
        //         attrs:{
        //             style:{
        //                 display:"none",
        //             },
        //             textContent:"123"
        //         },
        //         children:[
        //             {
        //                 nodeName:"span",
        //                 xpath:"//div[1]/span[1]",
        //                 attrs:{
        //                     style:{
        //                         display:"none",
        //                     }
        //                 }
        //             },{
        //                 nodeName:"input",
        //                 xpath:"//div[1]/input[1]",
        //                 attrs:{
        //                 }
        //             },{
        //                 nodeName:"span",
        //                 xpath:"//div[1]/span[2]",
        //                 attrs:{
        //                     style:{
        //                         display:"block",
        //                     }
        //                 }
        //             }
        //         ]
        //     }
        // ];
        // let obj1 = _.merge(obj2, obj3)
        // console.log("Diff:",obj1,obj2,obj3)
        // console.log(`ABC:`, newDate, oldDate)
    }
}