import { createApp } from 'vue'
import api from '@/api/data.js';
import App from './App.vue'
import { createStore } from 'vuex'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Tools from "@/utils/tools.js";
import { createRouter, createWebHistory } from 'vue-router';

const BrowserPage = () => import('@/views/Browser.vue');
const LoginPage = () => import('@/views/Login.vue');
const MiddlePage = () => import('@/views/Middle.vue');
const FilePage = () => import('@/views/File.vue');
const IframePage = () => import('@/views/Iframe.vue');

let router = createRouter({
	base: '/',
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: BrowserPage,
		}, {
			path: '/login',
			name: "login",
			component: LoginPage
		}, {
			path: '/mid',
			name: "mid",
			component: MiddlePage
		}, {
			path: '/file',
			name: "file",
			component: FilePage
		}, {
			path: '/iframe',
			name: "iframe",
			component: IframePage

			// },{
			// 	path: '/browser/:path?',
			// 	name:"browser",
			// 	component: BrowserPage,
			//     props: true
			// },{
			// 	path: '/:path(.*)',
			// 	component: BrowserPage,
		}, {
			path: '/browser',
			component: BrowserPage
		}, {
			path: '/browser/:path(.*)',
			component: BrowserPage,
		}
	]
});

let parseUrlParams = (url) => {
	const params = {};
	const regex = /[?&]([^=#]+)=([^&#]*)/g;
	let match;
	
	while ((match = regex.exec(url)) !== null) {
		let key = decodeURIComponent(match[1]);
		let value = decodeURIComponent(match[2]);
		params[key] = value;
	}
	
	return params;
  }

// 路由守卫
router.beforeEach((to, from, next) => {
	let isLogin = Boolean(Tools.getSItem("isLogin"));
	// console.log(`isLogin:`,typeof isLogin,isLogin)
	// console.log(`ABC:`, to.path, from.path, )
	if (isLogin && /^\/file/.test(to.path)) {
		// if (isLogin && /^\/file/.test(to.path) && /^\/browser/.test(from.path)) {
		let url = location.pathname + location.search + location.hash;
		// console.log(`url:`,url)
		sessionStorage.setItem("path", /^\/browser/.test(url) ? url : "");
		next()
	}else if (isLogin && /^\/browser$/.test(to.path) && /^\/file/.test(from.path)) {
		let url = sessionStorage.getItem("path");
		let params = parseUrlParams(url);
		console.log(`ABC2:`, to.path,url,params)
		// url ? next({ path: url, query:params }) : next(); 
		url ? (location.href = url) : next();
	}else if (isLogin) {
		next()
	} else if (to.path == "/login") {
		next();
	} else {
		next({
			path: "/login"
		})
	}
})

const store = createStore({
	state() {
		return {
			list: [
				// {
				// 	site: "www.baidu.com",
				// }
			]
		}
	},
	mutations: {
		setState(state, val) {
			state.list = val;
		}
	},
	actions: {
		setState(context) {
			context.commit('setState', []);
		}
	}
})

api.getSites().then((res) => {
	store.commit('setState', res.results);
})

createApp(App).use(router).use(store).use(ElementPlus).use().mount('#app')
