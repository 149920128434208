import request from '@/utils/request.js';// 
const baseUrl = process.env.NODE_ENV === 'production' ? "" : ""//"http://10.91.204.99";//"http://10.211.55.7"

const Apis = {

    /** 获取Ajax的BaseUrl
     *
     */
    getBaseUrl(){
        return baseUrl;
    },

    /** 获取受限站点及配置信息
     *
     */
    getSites: () => request.get(`${baseUrl}/manager/server/v1.0/restrictedListGroups/`),

}

export default Apis;